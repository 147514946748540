

const NavButton = ({title}) => {

	return (
		<div className="hidden md:flex lg:flex pt-2 pb-2 pl-12 pr-12 h-fit md:h-full lg:h-full grid items-center theme-bg ml-auto mr-20 cursor-pointer select-none hover:opacity-80">
			<span className="text-white uppercase text-2xl font-bold">{title}</span>
		</div>
	)
}

export default NavButton