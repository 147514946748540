import axios from "axios"

export const sendContactFormMessage = async (fullName, email, messageBody) => {
	const contactFormMessageRequest = {
		url: process.env.REACT_APP_CONTACT_FROM_API_URL,
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			fullName,
			email,
			message: messageBody,
		},
	}

	return await axios(contactFormMessageRequest)
}