

const Button = ({title, onClick}) => {

	return (
		<div className={`pl-12 pr-12 w-fit h-[50px] md:h-[60px] lg:h-[60px] grid items-center theme-bg cursor-pointer select-none hover:opacity-80`} onClick={onClick}>
			<span className="text-white uppercase text-xl font-bold">{title}</span>
		</div>
	)
}

export default Button