import Logo from '../../assets/images/logo.svg'
import NavButton from '../NavButton'

const Header = () => {

	return (
		<div className="md:pl-24 lg:pl-24 flex flex-col md:flex-row lg:flex-row justify-center items-center bg-black h-[100px] md:h-[90px] lg:h-[90px] md:top-0 md:sticky lg:top-0 lg:sticky">
			<img className="h-12" src={Logo} alt="dataloft logo" />
			<NavButton title="Contact Us"/>
		</div>
	)
}

export default Header