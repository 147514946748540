import TeamMemberImageNathan from '../../assets/images/team-member-nathan.png';
import TeamMemberImagePaulJones from '../../assets/images/team-member-paul-jones.png';
import TeamMemberImageAashir from '../../assets/images/team-member-aashir.png';

const TeamSection = () => {
	return (
		<div className="p-[40px] md:p-[80px] lg:p-[80px] flex flex-col md:flex-row lg:flex-row h-auto md:h-[900px] lg:h-[900px]">
			<div className="md:flex-[0.6] lg:flex-[0.6] flex flex-col justify-center">
				<span className="text-white text-[34px] md:text-[48px] lg:text-[48px] font-bold ">
					<h1>MEET THE MASTERMINDS</h1>
					<h1>BEHIND THE SCENES</h1>
				</span>
				<p className="mt-[25px] w-[95%] md:w-[88%] lg:w-[88%] md:text-[24px] text-[14px] lg:text-[24px] text-[#717173] leading-loose">The Dataloft team is comprised of some of the most brilliant minds in the industry. With a passion for all things digital, the team produces and champions new technology for the next generation of Internet, software and robotic users.</p>
			</div>
			<div className="md:flex-[0.4] lg:flex-[0.4] flex-col items-center justify-center">
				<div className="team-member-image-container">
					<img className="h-[250px] md:h-[430px] lg:h-[430px] w-max object-cover" src={TeamMemberImageNathan} alt="team-member-img" />
					<div className="md:w-[430px] lg:w-[430px] p-[10px] grid place-items-center theme-bg text-white uppercase leading-tight">
						<p className="text-[32px] font-bold">Nathan Senn</p>
						<p className="text-[24px]">CHIEF OPERATING OFFICER</p>
					</div>
				</div>
				<div className="w-[250px] md:w-[430px] lg:w-[430px] mt-[25px] flex flex-wrap justify-center gap-[30px]">
					<img className="team-member-small-image" src={TeamMemberImageNathan} alt="team-member-small-img" />
					<img className="team-member-small-image" src={TeamMemberImagePaulJones} alt="team-member-small-img" />
					<img className="team-member-small-image" src={TeamMemberImageAashir} alt="team-member-small-img" />
				</div>
			</div>
		</div>
	)
}

export default TeamSection