import DataloftLogo from "../../assets/images/dataloft-logo.svg"
import DBuzzLogo from "../../assets/images/dbuzz-logo.svg"
import RetzarkLogo from "../../assets/images/retzark-logo.svg"
import HelixLogo from "../../assets/images/helix-logo.svg"

const LogosSection = () => {
	return (
		<div className="pt-[50px] pb-[50px] md:pt-0 lg:pt-0 md:pb-0 lg:pb-0 flex flex-col md:flex-row lg:flex-row items-center justify-between h-[530px] md:h-[220px] lg:h-[220px] pl-14 pr-14 theme-bg">
			<img src={DataloftLogo} className="w-[252px]" alt="logo" />
			<img src={RetzarkLogo} className="w-[271px]" alt="logo" />
			<img src={DBuzzLogo} className="w-[182px] mb-4" alt="logo" />
		</div>
	)
}

export default LogosSection