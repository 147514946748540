import { useState } from 'react'
import Button from '../../components/Button'
import * as EmailValidator from 'email-validator';
import { sendContactFormMessage } from '../../api/handlers';

const FooterSection = () => {

	const [fullName, setFullName] = useState('')
	const [email, setEmail] = useState('')
	const [messageBody, setMessageBody] = useState('')
	const [contactFormSent, setContactFormSent] = useState(false)

	const handleSendContactForm = () => {
		if(fullName && EmailValidator.validate(email) && messageBody) {
			sendContactFormMessage(fullName, email, messageBody)
			.then((res) => {
				if(res.data.status === 'success') {
					setContactFormSent(true)
				}
 			})
		} else {
			alert('Please fill required fields')
		}
	}

	return (
		<div className="flex flex-col justify-evenly md:justify-start lg:md:justify-start md:flex-row lg:flex-row h-auto md:h-[572px] lg:h-[572px] border-t-[3px] border-[#444444]">
			<div className="md:flex-[0.6] lg:flex-[0.6] flex flex-col items-center justify-center p-[15px] bg-cover" style={{ backgroundImage: `url(${require('../../assets/backgrounds/talk-to-us-bg.svg').default})` }}>
				<span>
					<h1 className="w-full text-white text-[48px] font-bold">TALK TO US</h1>
					{!contactFormSent ?
						<span className="flex flex-col gap-[20px] mt-[15px]">
							<input className="contact-form-input" type="text" placeholder="Full Name *" value={fullName} onChange={(e) => setFullName(e.target.value)} />
							<input className="contact-form-input" type="text" placeholder="Email *" value={email} onChange={(e) => setEmail(e.target.value)}/>
							<input className="contact-form-input" type="text" placeholder="Write your message here... *" value={messageBody} onChange={(e) => setMessageBody(e.target.value)}/>
							<span className="mt-[15px]"><Button title="SEND" onClick={handleSendContactForm}/></span>
						</span> :
						<span className="flex flex-col gap-[20px] mt-[15px]">
							<p className="w-[85%] text-[14px] md:text-[18px] lg:text-[18px] text-white font-bold">Your message has been sent, Thank you for contacting <b className="text-transparent bg-clip-text theme-bg">Dataloft</b>.</p>
						</span>
					}
				</span>
			</div>
			<div className="h-[400px] flex flex-col justify-center items-start md:flex-[0.4] lg:flex-[0.4] pl-[45px]">
				<div className="flex justify-between w-[80%]">
					<span>
						<h1 className="text-white text-[24px] font-bold">QUICK LINKS</h1>
						<div className="flex flex-col mt-[30px] text-[14px] text-white leading-loose">
							<a href="/#">WORK</a>
							<a href="/#">ABOUT</a>
							<a href="/#">TALK TO US</a>
						</div>
					</span>
					<span>
						<h1 className="text-white text-[24px] font-bold">SAY HELLO</h1>
						<div className="flex flex-col mt-[30px] text-[14px] text-white leading-loose font-[500]">
							<a className="footer-nav-link" href="mailto:info@dataloft.llc">info@dataloft.llc</a>
							<a className="footer-nav-link" href="mailto:hr@dataloft.llc">hr@dataloft.llc</a>
						</div>
					</span>
					<span></span>
				</div>
				<div className="mt-[55px] flex text-white text-[12px] gap-[5px]">
					<p>Copyright ©</p>
					<p>|</p>
					<p>Dataloft Website</p>
					<p>|</p>
					<p>All Rights Reserved</p>
					<p>|</p>
					<p>Content Protected</p>
				</div>
			</div>
		</div>
	)
}

export default FooterSection