import Hero2Background from '../../assets/backgrounds/hero-2-background.png';

const HeroSection2 = () => {
	return (
		<div className="md:pl-24 md:pr-24 lg:pl-24 lg:pr-24 flex flex-col md:flex-row lg:flex-row items-center md:items-start lg:items-start h-[165%] md:h-[850px] lg:h-[850px]">
			<img style={{ transform: 'translateY(-80px)' }} className="h-[600px] md:h-[850px] lg:h-[850px]" src={Hero2Background} alt="hero-2-background" />
			<div className="w-full md:mt-20 lg:mt-20 md:pl-14 sm:pl-14">
				<span className="text-white text-[32px] md:text-[48px] lg:text-[48px] leading-snug font-bold flex flex-col items-center md:items-start lg:items-start">
					<h1>BIG THINKERS,</h1>
					<h1>EVEN BIGGER DOERS.</h1>
				</span>
				<div className="flex flex-col items-center md-items-start lg:items-start text-[#717173] text-[14px] md:text-[24px] lg:text-[24px] leading-loose">
					<p className="mt-8 w-[300px] md:w-auto lg:w-auto">We design technology of the future and make it available to everyone today. We believe that technology should make life easier, not more complicated.</p>
					<p className="mt-8 w-[300px] md:w-auto lg:w-auto">We are constantly pushing the boundaries of what is possible to create products that are not only beautiful and functional but also easy to use. We are passionate about making the world a better place and believe that technology is the key to making this happen.</p>
				</div>
			</div>

		</div>
	)
}
export default HeroSection2