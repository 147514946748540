import HeroSectionBackground from '../../assets/backgrounds/hero-background.png'
import Button from '../../components/Button'

const HeroSection = () => {
	return (
		<div style={{ backgroundImage: `url(${HeroSectionBackground})` }} className="flex-col justify-start pl-6 md:pl-20 lg:pl-20 md:pr-20 lg:pr-20 h-[700px] md:h-[850px] lg:h-[850px] bg-cover border-b-[3px] border-[#444444]">
			<div className="flex-col pt-[120px] leading-tight">
				<span className="text-transparent bg-clip-text theme-bg text-[36px] md:text-[72px] lg:text-[72px] font-bold">TECHNOLOGY</span>
				<br />
				<span className="text-transparent bg-clip-text theme-bg text-[36px] md:text-[72px] lg:text-[72px] font-bold">OF VERIFICATION.</span>
			</div>

			{/* MISSION */}
			<div className="mt-14">
				<p className="w-[90%] md:w-[72%] lg:w-[72%] leading-[2.5] text-white md:text-[24px] lg:text-[24px]">Our mission at Dataloft is to build and deliver the next generation of the Internet (Web3), with verifiably trustworthy and secure solutions that make transacting online trustless</p>
			</div>

			<div className="mt-12">
				<Button title="Read More"/>
			</div>
		</div>
	)
}

export default HeroSection