import Header from './components/Header'
import FooterSection from './sections/FooterSection'
import HeroSection from './sections/HeroSection'
import HeroSection2 from './sections/HeroSection2'
import LogosSection from './sections/LogosSection'
import TeamSection from './sections/TeamSection'

function App() {
  return (
    <div className="app h-screen overflow-scroll">
      <Header />
      <HeroSection />
      <HeroSection2 />
      <LogosSection />
      <TeamSection />
      <FooterSection />
    </div>
  );
}

export default App;
